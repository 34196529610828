import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/homepage/Hero";
import {graphql, useStaticQuery} from "gatsby";
import Highlights from "../components/homepage/Highlights";
import CaseStudies from "../components/homepage/CaseStudies";
import Posts from "../components/homepage/Posts";
import Workflow from "../icons/workflow.svg";
import Pdf from "../icons/pdf.svg";
import Responsive from "../icons/responsive.svg";
import VideoSection from "../components/VideoSection";
import {getImage} from "gatsby-plugin-image";
import BuyersGuideLink from "../components/BuyersGuideLink";


export default function Syllabus()  {

  const data = useStaticQuery(graphql`{
  homehero: file(absolutePath: {regex: "/syllabus-management.jpg/"}) {
    childImageSharp {
      gatsbyImageData(width: 650, layout: CONSTRAINED)
    }
  }
}
`);


  return (
    <Layout>

      <Hero
        title={'Syllabus Management Software'}
        text={'Empower faculty and staff to create templated, consistent syllabi with our easy-to-use system.'}
        linkText={'Get Started'}
        linkPath={'/start/'}
        buttonStyle={'button'}
        imgSrc={getImage(data.homehero)}
        imgAlt={'Faculty using syllabus management software on a laptop.'}
      />
      <Highlights
        title={'Streamline Your Syllabus Management Process'}
        text={'Manage all your syllabi in one easy-to-use system.'}
        highlightOneIcon={<Workflow />}
        highlightOneTitle={'Empower Faculty and Staff'}
        highlightOneText={'Workflows and approvals allow faculty and staff to manage their own content, while still ensuring everything goes through proper approval processes.'}
        highlightTwoIcon={<Pdf />}
        highlightTwoTitle={'Create Templated PDFs'}
        highlightTwoText={'Each syllabus is available as a webpage and as a templated, consistent PDF.'}
        highlightThreeIcon={<Responsive />}
        highlightThreeTitle={'Customizable & Flexible'}
        highlightThreeText={'Fully customizable designs and layouts, and workflow capabilities that allow you to set granular permissions and approval processes before publishing syllabi.'}

      />
      <CaseStudies/>


      <VideoSection
        title={'Create consistent, templated syllabi'}
        text={'Advanced workflows and approvals empower faculty and staff to manage syllabi efficiently. '}
        vimeoid={'510312431'}
      />
      <BuyersGuideLink />

      <Posts
        tag={'syllabus management software'}
      />

    </Layout>
  );

}

export function Head(props) {
  return (
    <Seo
      title="Syllabus Management Software"
      description='Syllabus management software built for colleges and universities — Clean Catalog.'
      canonical={props.location.pathname}
    />
  )
}

